
<script setup lang="ts">
    import { ref, watch, computed, inject, onMounted } from "vue";
    import { type DataRow, type DataItemModel } from "o365-data-object";
    import { RelatedItemsProvide } from "./RelatedItems.RelatedItemsProvider.vue";
    import { isMobile, isMobileOrTablet } from "o365.GlobalState.ts";
    import { OActionDelete } from "o365-data-components";
    import { OSwitch } from "o365-ui-components";

    import RelatedItemMenu from "./RelatedItems.RelatedItemMenu.vue";

    export interface IProps {
        row: DataRow;
        type?: string;
        text?: string;
        extraText?: string;
        href?: string;
        remark?: string;
        onClick?: Function;
        strikethrough?: boolean;
    };

    export interface IEmits {
        (key: "click", row: DataItemModel): void;
    };

    const props = defineProps<IProps>();
    const emit = defineEmits<IEmits>();
    const model = defineModel<boolean>();

    const dataObject = computed(() => props.row.$.dataObject);
    const isClickable = computed(() => props.href || props.onClick);
    const isLoading = computed(() => props.row.isNewRecord || props.row.isDeleting || props.row.isSaving);

    const { textOnly } = inject<RelatedItemsProvide>("RelatedItemsProvider");

    function onClick() {
        emit("click", props.row);
    }
</script>

<template>
    <!-- mobile -->
    <template v-if="isMobile">
        <template v-if="textOnly">
            <span>{{ text ?? "" }}</span>
        </template>
        <template v-else>
            <div class="d-flex flex-column border-top">
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                        <slot>
                            <a class="d-block" :class="{ 'text-primary cursor-pointer': isClickable, 'text-black': !isClickable }" style="padding: 0.75rem 1rem;" :href="href" :target="isMobile ? undefined : '_blank'" @click="onClick">
                                <div>
                                    <template v-if="type">
                                        <div class="font-n4 uppercase tracking-4 text-muted">{{ type }}</div>
                                    </template>
                                    <div>
                                        <template v-if="remark">
                                            <span class="me-1" :title="remark">
                                                <i class="bi bi-exclamation-triangle text-warning"></i>
                                            </span>
                                        </template>
                                        <span class="fw-medium" :class="{ 'text-decoration-line-through': strikethrough }">{{ text }}</span>
                                    </div>
                                </div>
                            </a>
                        </slot>
                    </div>
                    <div style="padding: 0 1.25rem;">
                        <RelatedItemMenu :row="row" :title="text" :loading="isLoading">
                            <slot name="actions" />
                        </RelatedItemMenu>
                    </div>
                </div>

                <!-- bottom -->
                <template v-if="extraText || $slots.input">
                    <div class="px-3 pb-3">
                        <!-- extra text -->
                        <template v-if="extraText">
                            <div class="text-muted" style="margin-top: -0.75rem;">{{ extraText }}</div>
                        </template>

                        <!-- input -->
                        <template v-if="$slots.input">
                            <slot name="input" />
                        </template>
                    </div>
                </template>
            </div>
        </template>
    </template>

    <!-- desktop -->
    <template v-else>
        <div class="d-flex align-items-baseline gap-2 c-related-item">
            <div class="d-flex align-items-center text-truncate">
                <OButton variant="link" color="black" :icon="isLoading ? undefined : 'bi-dash'" :loading="isLoading" />

                <template v-if="remark">
                    <div :title="remark">
                        <i class="bi bi-exclamation-triangle text-warning"></i>
                    </div>
                </template>

                <!-- <div class="d-flex align-items-baseline"> -->
                    <!-- <OButton size="lg" variant="link" :text="[type, text].filter(e => e).join(': ')" :href="href" newTab /> -->
                    <!-- <template v-if="extraText"> -->
                        <!-- <span class="ms-1 text-muted">{{ extraText }}</span> -->
                    <!-- </template> -->
                <!-- </div> -->

                <slot>
                    <a
                        class="text-truncate"
                        :class="{ 'text-primary cursor-pointer': isClickable, 'text-black': !isClickable, 'text-decoration-line-through': strikethrough }"
                        :title="[[type, text].filter(e => e).join(': '), extraText].filter(e => e).join(' ')"
                        :href="href"
                        :target="isMobileOrTablet ? undefined : '_blank'"
                        @click="onClick"
                    >
                        <span>{{ [type, text].filter(e => e).join(': ') }}</span>
                        <template v-if="extraText">
                            <span class="ms-1 text-muted">{{ extraText }}</span>
                        </template>
                    </a>
                </slot>
            </div>
            <template v-if="$slots.input">
                <slot name="input" />
            </template>

            <!-- menu -->
            <template v-if="$slots.actions">
                <div>
                    <RelatedItemMenu :row="row" :title="text">
                        <slot name="actions" />
                    </RelatedItemMenu>
                </div>
            </template>
            <template v-else-if="dataObject.allowDelete">
                <OButton variant="link" icon="bi-x-circle" :title="$t('Delete record')" @click="row.delete()" />
            </template>

            <!-- <template v-if="dataObject.allowDelete"> -->
                <!-- <OActionDelete :row="row" class="ms-1 btn btn-sm btn-link p-0"> -->
                    <!-- <i class="bi bi-x-circle"></i> -->
                <!-- </OActionDelete> -->
            <!-- </template> -->
            <!-- <div class="px-2 c-related-item-menu"> -->
                <!-- <RelatedItemMenu :row="row" :title="text" /> -->
            <!-- </div> -->
        </div>
    </template>
</template>

<style>
    /* .c-related-item-menu { */
        /* opacity: 0%; */
        /* transition: all 200ms ease-in-out; */
    /* } */

    /* .c-related-item:hover > .c-related-item-menu, .c-related-item-menu:has(> div.dropdown > ul.dropdown-menu.show) { */
        /* opacity: 100%; */
    /* } */

    /* cache fix */
</style>
